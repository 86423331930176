import { FormControl, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function DatePicker(props) {

  const { label, value, register, onChange, size, minDate, maxDate, disabled } = props

  return (
    <FormControl variant="standard" fullWidth sx={{ my: 1.3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          inputFormat="dd-MM-yyyy"
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          {...register}
          onChange={onChange}
          renderInput={(params) => <TextField size={size} InputLabelProps={{ shrink: true }} {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export default DatePicker