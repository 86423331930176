import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://invoizrpro.imaginex.com.pk/api',
});

instance.interceptors.request.use((request) => {

  let user = JSON.parse(localStorage.getItem('user'))

  if (request.url.includes('print')) {
    request.responseType = 'blob'
  }
  request.headers = {
    'Accept': "application/json, text/plain, */*",
    'Authorization': `Bearer ${user?.token}`,
  }
  return request
});

instance.interceptors.response.use((response) => {
  if (response) {
    return response
  }
}, (error) =>
  Promise.reject(
    error
  )
);

export default instance;