import React from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import Colors from 'app/assets/style/Colors';
import { Capitalize } from 'app/utils';

const useStyle = makeStyles((theme) => ({
  fontSize: {
    fontSize: '18px !important',
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  }
}))

function Breadcrumb({ removeLastWord }) {

  const classes = useStyle();
  let { pathname } = useLocation();
  pathname = pathname.substring(1)
  let path = pathname.split('/')

  if (removeLastWord) {
    path.splice(path.length - 1, 1)
  }

  return (
    <Breadcrumbs aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" color={Colors.gunMetal} />}
      sx={{ my: 1, '.MuiBreadcrumbs-separator': { mx: 0 } }}
    >
      {path.splice(0, path.length - 1).map((item, index) => (
        <Typography key={index} className={classes.fontSize} color={Colors.gunMetal}>{Capitalize(item.replace(/-/g, ' '))}</Typography>
      ))}
      <Typography className={classes.fontSize} color="secondary">{Capitalize(path[path.length - 1].replace(/-/g, ' '))}</Typography>
    </Breadcrumbs>
  )
}

export default Breadcrumb