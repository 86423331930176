import { post } from 'app/apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  logout: async () => {
    const data = await post(AuthRoutes.logout);
    return data;
  },
  refresh: async () => {
    const data = await post(AuthRoutes.refresh);
    return data;
  },
}

export default AuthServices