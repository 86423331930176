import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, Grid, IconButton, Input, InputAdornment, InputLabel, Typography } from '@mui/material';
import { Add, Business, PersonOutlineOutlined, Save } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid'

import { Currencies, InvoiceTypes } from 'app/utils/DropdownData';
import { getDateFormate, getDateTime } from 'app/utils';
import Colors from 'app/assets/style/Colors';
import { CameraIcon, DeleteIcon } from 'app/assets/Images';
import Breadcrumb from 'app/components/breadcrumb/Breadcrumb';
import { ActionButton, PrimaryButton, SecondaryButton } from 'app/components/buttons/Buttons';
import { ErrorToaster, SuccessToaster } from 'app/components/toaster/Toaster';
import SimpleDialog from 'app/components/dialog/SimpleDialog';
import InputField from 'app/components/fields/InputField';
import SelectField from 'app/components/fields/SelectField';
import DatePicker from 'app/components/fields/DatePicker';
import DocumentServices from 'app/apis/document/DocumentServices';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from 'app/hooks/UseAuth';

function EditInvoice() {

  const navigate = useNavigate();
  const { userLogout } = useAuth();
  const { id } = useParams();

  const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  // *For Dialog Box
  const [addressDialogBox, setAddressDialogBox] = useState(false);

  // *For Image
  const [image, setImage] = useState();

  // *For Select Invoice
  const [selectedInvoiceType, setSelectedInvoiceType] = useState('Invoice');
  const [selectedCurrency, setSelectedCurrency] = useState('SAR');

  // *For Sender Detail
  const [senderDetail, setSenderDetail] = useState();
  const [isSender, setIsSender] = useState(false);

  // *For Recipient Detail
  const [recipientDetail, setRecipientDetail] = useState();

  // *For Dates
  const [issueDate, setIssueDate] = useState(moment().format());
  const [dueDate, setDueDate] = useState(moment().add(2, 'days'));

  // *For Products list
  const [products, setProducts] = useState([{ id: uuidv4() }]);

  // *For Document Id
  const [documentId, setDocumentId] = useState('');

  // *For Invoice Summary
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  // *For Handle Issue Date
  const handleIssueDate = (newDate) => {
    try {
      if (!newDate) return
      setIssueDate(newDate)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Handle Due Date
  const handleDueDate = (newDate) => {
    try {
      if (!newDate) return
      setDueDate(newDate)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Upload Image
  const uploadImage = (e) => {
    e.preventDefault();
    let files = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files);
  }

  // *For Update Product 
  const handleUpdateProduct = (value, id, key) => {
    try {
      const index = products.findIndex((e) => e.id === id)
      let updateProduct = [...products]
      updateProduct[index][key] = value
      setProducts(updateProduct)
    } catch (error) {
      console.log('file: index.js:64 => handleUpdateProduct => error', error)
    }
  }

  // *For Add Product
  const addProduct = () => {
    try {
      const updateProduct = [...products]
      let obj = { id: uuidv4() }
      updateProduct.push(obj)
      setProducts(updateProduct)
    } catch (error) {
      console.log('file: index.js:79 => addProduct => error', error)
    }
  }

  // *For Delete Product
  const deleteProduct = (id) => {
    try {
      const index = products.findIndex((e) => e.id === id)
      let updateProduct = [...products]
      console.log(products)
      updateProduct.splice(index, 1);
      setProducts(updateProduct)
    } catch (error) {
      console.log('file: index.js:98 => deleteProduct => error', error)
    }
  }

  // *For Add Sender/Recipient Invoice Detail
  const addInvoiceDetail = (formData) => {
    try {
      if (isSender) {
        setSenderDetail(formData)
      } else {
        setRecipientDetail(formData)
      }
      setAddressDialogBox(false)
      reset()
    } catch (error) {
      console.log('file: index.js:98 => deleteProduct => error', error)
    }
  }

  // *For Fill Sender/Recipient Invoice Detail
  const fillDefaultDetail = (data) => {
    try {
      if (data) {
        setValue('companyName', data.companyName)
        setValue('lineAddress1', data.lineAddress1)
        setValue('lineAddress2', data.lineAddress2)
        setValue('vat', data.vat)
        setValue('crNo', data.crNo)
        setValue('salePerson', data.salePerson)
      }
    } catch (error) {
      console.log('file: index.js:98 => deleteProduct => error', error)
    }
  }

  // *For Save Invoice
  const saveInvoice = async () => {
    setLoading(true)
    try {
      products.forEach(element => { delete element.id });
      let sender = [{
        company_name: senderDetail.companyName,
        sender_tax_vat_title: "VAT NO.",
        vat_number: senderDetail.vat,
        sender_first_name: "first name",
        sender_last_name: "last name",
        address_line_1: senderDetail.lineAddress1,
        address_line_2: senderDetail.lineAddress2,
        sender_postal_code: "postal code",
        sender_city: "city",
        sender_country: "pakistan",
        sender_phone_number: "5213153",
        sender_email: "smdlakl@gmail.com",
        sender_website: "https://stackoverflow.com/",
        cr_no: senderDetail.crNo,
        sales_person: senderDetail.salePerson,
      }]
      let receiver = [{
        company_name: recipientDetail?.companyName,
        sender_tax_vat_title: "titl2e",
        vat_number: recipientDetail.vat,
        rec_first_name: "first name",
        rec_last_name: "last name",
        address_line_1: recipientDetail.lineAddress1,
        address_line_2: recipientDetail.lineAddress2,
        rec_postal_code: "code",
        rec_city: "city",
        rec_country: "country",
        rec_phone: "033022561334",
        rec_email: "emai2l@gmail.com",
        rec_website: "websit2e",
        cr_no: recipientDetail.crNo
      }]
      let obj = {
        sender_data: sender,
        recipient_data: receiver,
        line_items: products,
        document_type: selectedInvoiceType,
        document_number: getValues('invoiceNo'),
        document_currency: selectedCurrency,
        document_additional_detail: "detail",
        document_template: null,
        issue_date: getDateFormate(issueDate),
        due_date: getDateFormate(dueDate),
        image: image
      }
      const { data, message, status } = await DocumentServices.updateDocument(id, obj)
      if (status === 'Token is Expired') return userLogout()
      setDocumentId(data.id)
      SuccessToaster(message)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Download Document
  const downloadPdf = async () => {
    setPdfLoading(true)
    try {
      const data = await DocumentServices.printDocument(documentId)
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setPdfLoading(false)
    }
  }

  // *For Get Invoice Detail
  const getInvoiceDetail = async () => {
    setLoader(true)
    try {
      const { data, status } = await DocumentServices.getDocumentDetail(id)
      if (status === 'Token is Expired') return userLogout()
      setDocumentId(data.id)
      handleDueDate(data.document_due_date)
      handleIssueDate(data.document_issue_date)
      setSelectedCurrency(data.document_currency)
      setValue('invoiceNo', data.document_number)
      setSenderDetail({
        companyName: data.sender_name,
        vat: data.sender_tax_vat_number,
        lineAddress1: data.sender_address_1,
        lineAddress2: data.sender_address_2,
        crNo: data.sender_cr_number,
        salePerson: data.document_sales_person
      })
      setRecipientDetail({
        companyName: data.rec_name,
        vat: data.rec_tax_vat_number,
        lineAddress1: data.rec_address_1,
        lineAddress2: data.rec_address_2,
        crNo: data.rec_cr_number,
        salePerson: data.rec_sales_person
      })
      let product = []
      data.items.forEach(element => {
        let obj = {
          id: element.id,
          name: element.name,
          description: element.description,
          quantity: element.quantity,
          unitPrice: element.rate,
          tax: element.tax_rate
        }
        product.push(obj)
      });
      setImage(data.base64_image)
      setProducts(product)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (id) {
      getInvoiceDetail()
    }
  }, [id]);

  useEffect(() => {
    let subTotal = 0
    let tax = 0
    let total = 0

    products.forEach(element => {
      let st = (element?.quantity ?? 0) * (element?.unitPrice ?? 0)
      let t = ((element?.tax ?? 0) / 100) * st
      let gt = st + t
      subTotal += st
      tax += t
      total += gt
    });
    setSubTotal(Number(subTotal).toFixed(2))
    setTax(Number(tax).toFixed(2))
    setTotal(Number(total).toFixed(2))
  }, [products]);

  return (
    <Fragment>

      {/* ========== Sender/Recipient Invoice Detail Dialog Box ========== */}
      <SimpleDialog open={addressDialogBox} onClose={() => { setAddressDialogBox(false); reset() }} title={isSender ? 'Sender Detail' : 'Recipient Detail'}>
        <Box component="form" onSubmit={handleSubmit(addInvoiceDetail)}>
          <Grid container spacing={1} alignItems="center">
            <Grid item md={6}>
              <InputLabel>Company Name</InputLabel>
              <InputField
                size={'small'}
                placeholder={'Company Name.'}
                error={errors?.companyName?.message}
                register={register("companyName", {
                  required: 'Please enter company name.',
                })}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Tax/Vat Reg. No.</InputLabel>
              <InputField
                size={'small'}
                type={'number'}
                placeholder={'Tax/Vat Reg. No.'}
                inputProps={{ minLength: 15 }}
                error={errors?.vat?.message}
                register={register("vat", {
                  required: 'Please enter Tax/Vat Reg. No.',
                })}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Line Address 1</InputLabel>
              <InputField
                size={'small'}
                placeholder={'Line address 1.'}
                error={errors?.lineAddress1?.message}
                register={register("lineAddress1", {
                  required: 'Please enter Line address 1.',
                })}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Line Address 2</InputLabel>
              <InputField
                size={'small'}
                placeholder={'Line address 2.'}
                register={register("lineAddress2")}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>CR No.</InputLabel>
              <InputField
                size={'small'}
                type={'number'}
                placeholder={'Cr No.'}
                error={errors?.crNo?.message}
                register={register("crNo", {
                  required: 'Please enter Cr No.',
                })}
              />
            </Grid>
            {isSender ? (
              <Grid item md={6}>
                <InputLabel>Sale Person</InputLabel>
                <InputField
                  size={'small'}
                  placeholder={'Sale Person'}
                  register={register("salePerson")}
                />
              </Grid>
            ) : (
              <Grid item md={6}>
                <InputLabel>Invoice Currency</InputLabel>
                <SelectField
                  size={'small'}
                  selected={selectedCurrency}
                  options={Currencies}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, textAlign: 'center' }}>
            <SecondaryButton
              title="cancel"
              onClick={() => { setAddressDialogBox(false); reset() }}
            />
            <Box sx={{ mx: 0.5 }} />
            <PrimaryButton
              title="Save"
              type={"submit"}
              btnColor={Colors.secondaryGradient}
            />
          </Box>
        </Box>
      </SimpleDialog>

      {/* ========== Heading & Action Button ========== */}
      <Grid container spacing={1} sx={{ mt: 1, mb: 3 }} alignItems="center">
        <Grid item md={6}>

          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Edit invoice
          </Typography>

          {/* ========== Breadcrumb ========== */}
          <Breadcrumb />

        </Grid>
        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <PrimaryButton
            title="Download PDF"
            loading={pdfLoading}
            btnColor={Colors.primaryGradient}
            // startIcon={<ExcelExport />}
            disabled={documentId ? false : true}
            onClick={() => downloadPdf()}
          />
          <Box sx={{ mx: 0.5 }} />
          <PrimaryButton
            title="Save"
            loading={loading}
            btnColor={Colors.secondaryGradient}
            startIcon={<Save />}
            onClick={() => saveInvoice()}
          />
        </Grid>
      </Grid>

      <Box sx={{ bgcolor: Colors.white, p: 3, borderRadius: 3, boxShadow: 'rgb(145 158 171 / 16%) 0px 4px 8px 0px' }} >

        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item md={6}>
            <Box
              sx={{
                bgcolor: Colors.ghostWhite,
                boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12)',
                borderRadius: 1,
                textAlign: 'center',
                p: 0.5,
                my: 1.5,
                width: '200px',
                height: '130px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {image ? (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="img"
                    src={image}
                    alt="module" width='200px' height='130px'
                  />
                  <IconButton
                    component="span"
                    disableRipple
                    sx={{
                      position: 'absolute',
                      right: '-6px', top: '-6px',
                      bgcolor: Colors.desertStorm, padding: "1px"
                    }}
                    onClick={() => setImage()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <Fragment>
                  <Input
                    type='file'
                    accept="image/png, image/jpg, image/jpeg"
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      left: 0,
                      top: 0,
                      opacity: 0,
                      display: 'block',
                      '.MuiInputBase-input, .MuiInputBase-root': {
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }
                    }}
                    onChange={(e) => uploadImage(e)}
                  />
                  <Box>
                    <CameraIcon />
                    <Typography variant="body2" sx={{ fontSize: '10px', color: Colors.gunMetal, mt: 1 }}>
                      Choose logo or drop it here.
                    </Typography>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <SelectField
                  size={'small'}
                  selected={selectedInvoiceType}
                  options={InvoiceTypes}
                  onChange={(e) => setSelectedInvoiceType(e.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <InputField
                  size={'small'}
                  type={'number'}
                  placeholder={'Invoice No.'}
                  register={register("invoiceNo")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'center'} justifyContent={'end'}>
              <Grid item md={2}>
                <InputLabel sx={{ fontWeight: 700 }}>Issue date</InputLabel>
              </Grid>
              <Grid item md={6}>
                <DatePicker
                  size={'small'}
                  minDate={moment().format()}
                  value={issueDate}
                  onChange={(newDate) => handleIssueDate(newDate)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'center'} justifyContent={'end'}>
              <Grid item md={2}>
                <InputLabel sx={{ fontWeight: 700 }}>Due date</InputLabel>
              </Grid>
              <Grid item md={6}>
                <DatePicker
                  size={'small'}
                  value={dueDate}
                  onChange={(newDate) => handleDueDate(newDate)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent={'space-between'} sx={{ mt: 1 }}>
          <Grid item md={6}>
            <Box
              sx={{ cursor: 'pointer', bgcolor: Colors.ghostWhite, p: 2, borderRadius: 3, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12)' }}
              onClick={() => { fillDefaultDetail(senderDetail); setAddressDialogBox(true); setIsSender(true) }}
            >
              <Typography variant="h6" sx={{ mb: 2 }} >
                From
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Avatar sx={{ width: 50, height: 50, mr: 2 }}>
                  <Business />
                </Avatar>
                <Box flexGrow={1}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }} >
                    {senderDetail?.companyName ?? 'Sender Name'}
                  </Typography>
                  <Typography variant="body1">
                    {senderDetail?.lineAddress1 || senderDetail?.lineAddress2 ? `${senderDetail?.lineAddress1} ${senderDetail?.lineAddress2}` : 'Sender Contact Details'}
                  </Typography>
                  {senderDetail?.crNo &&
                    <Typography variant="body1">
                      CR No: {senderDetail?.crNo}
                    </Typography>
                  }
                  {senderDetail?.vat &&
                    <Typography variant="body1">
                      VAT No: {senderDetail?.vat}
                    </Typography>
                  }
                  {senderDetail?.salePerson &&
                    <Typography variant="body1">
                      Sale person: {senderDetail?.salePerson}
                    </Typography>
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{ cursor: 'pointer', bgcolor: Colors.ghostWhite, p: 2, borderRadius: 3, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12)' }}
              onClick={() => { fillDefaultDetail(recipientDetail); setAddressDialogBox(true); setIsSender(false) }}
            >
              <Typography variant="h6" sx={{ mb: 2 }} >
                To
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Avatar sx={{ width: 50, height: 50, mr: 2 }}>
                  <PersonOutlineOutlined />
                </Avatar>
                <Box flexGrow={1}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }} >
                    {recipientDetail?.companyName ?? 'Recipient Name'}
                  </Typography>
                  <Typography variant="body1">
                    {recipientDetail?.lineAddress1 || recipientDetail?.lineAddress2 ? `${recipientDetail?.lineAddress1} ${recipientDetail?.lineAddress2}` : 'Recipient Contact Details'}
                  </Typography>
                  {recipientDetail?.crNo &&
                    <Typography variant="body1">
                      CR No: {recipientDetail?.crNo}
                    </Typography>
                  }
                  {recipientDetail?.vat &&
                    <Typography variant="body1">
                      VAT No: {recipientDetail?.vat}
                    </Typography>
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* ========== Product ========== */}
        <Box sx={{ mt: 4, mb: 1 }}>
          <Box sx={{ bgcolor: Colors.ghostWhite, p: 1, borderRadius: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Description
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Unit Price
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Tax %
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                <Typography variant="body1" sx={{ fontWeight: 700, textAlign: 'center' }}>
                  Sub Total
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {products.length > 0 &&
            products.map((item, index) => (
              <Grid key={index} container spacing={1} alignItems={'center'} >
                <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
                  <InputField
                    size={'small'}
                    placeholder={'Product Name'}
                    value={item?.name}
                    onChange={(e) => handleUpdateProduct(e.target.value, item.id, 'name')}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <InputField
                    size={'small'}
                    placeholder={'Description'}
                    value={item?.description}
                    onChange={(e) => handleUpdateProduct(e.target.value, item.id, 'description')}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                  <InputField
                    size={'small'}
                    type={'number'}
                    placeholder={'Quantity'}
                    value={item?.quantity}
                    onChange={(e) => handleUpdateProduct(e.target.value, item.id, 'quantity')}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                  <InputField
                    size={'small'}
                    type={'number'}
                    placeholder={'Unit Price'}
                    value={item?.unitPrice}
                    onChange={(e) => handleUpdateProduct(e.target.value, item.id, 'unitPrice')}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                  <InputField
                    size={'small'}
                    type={'number'}
                    placeholder={'Tax'}
                    value={item?.tax}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    onChange={(e) => handleUpdateProduct(e.target.value, item.id, 'tax')}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {selectedCurrency} {((item?.unitPrice ?? 0) * (item?.quantity ?? 0)).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={.5} sm={.5} md={.5} lg={.5} xl={.5}>
                  {products.length > 1 &&
                    <Box sx={{ display: 'flex', bgcolor: Colors.flashWhite, borderRadius: 4, width: 'fit-content', px: 1, py: 0.5 }}>
                      <ActionButton disableRipple aria-label="delete" onClick={() => deleteProduct(item.id)}>
                        <DeleteIcon />
                      </ActionButton>
                    </Box>
                  }
                </Grid>
              </Grid>
            ))
          }
          <Box sx={{ my: 1 }}>
            <PrimaryButton
              title="Add New Item"
              btnColor={Colors.secondaryGradient}
              startIcon={<Add />}
              onClick={() => addProduct()}
            />
          </Box>
        </Box>

        {/* ========== Invoice Summary ========== */}
        <Grid container spacing={1} sx={{ my: 1 }} justifyContent="end">
          <Grid item md={4}>
            <Box sx={{ bgcolor: Colors.ghostWhite, p: 1, borderRadius: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Invoice Summary
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <Typography variant="body1">
                SubTotal
              </Typography>
              <Typography variant="body1">
                {selectedCurrency} {subTotal}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <Typography variant="body1">
                Tax
              </Typography>
              <Typography variant="body1">
                {selectedCurrency} {tax}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <Typography variant="body1">
                Total
              </Typography>
              <Typography variant="body1">
                {selectedCurrency} {total}
              </Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>

    </Fragment>
  );
}

export default EditInvoice;