import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { CircleLoading, Preloader } from 'app/components/loaders/Loaders';

function AdminLayout({ children }) {

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>

      {/* ========== Sidebar ========== */}
      <Sidebar />

      <Box component="main" sx={{ width: 1 }}>

        {/* ========== Header ========== */}
        <Header />

        <Box sx={{ position: 'relative', overflowY: 'auto', py: 2.5, px: 4 }}>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Box>

      </Box>

    </Box>
  )
}

export default AdminLayout