import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import Colors from './assets/style/Colors';
import { ToasterContainer } from './components/toaster/Toaster';
import useAuth from './hooks/UseAuth';
import AuthRoutes from './routes/AuthRoutes';
import AdminRoutes from './routes/AdminRoutes';
import AuthLayout from './layout/auth';
import AdminLayout from './layout/admin';
import ScrollToTop from './hooks/ScrollToTop';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
      contrastText: Colors.white,
    },
    danger: {
      main: Colors.danger,
      contrastText: Colors.white,
    },
  },
  typography: {
    fontFamily: ["Poppins"],
    h6: {
      fontSize: 20,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 500
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    caption: {
      fontSize: 10,
      fontWeight: 300
    },
  },
});

function App() {

  const { user } = useAuth();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Toaster ========== */}
        <ToasterContainer />

        <Routes>

          <Route element={user?.token ? <Navigate to="/" /> : <AuthLayout />} >
            {AuthRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.token ? <AdminLayout /> : <Navigate to="/auth/login" />} >
            {AdminRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Scroll To Top ========== */}
        <ScrollToTop />

      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
