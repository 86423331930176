import useAuth from "app/hooks/UseAuth";

export const ErrorHandler = (error) => {
    console.log(error.response.data.message)

    const { userLogout } = useAuth();

    if (error.response) {
        if (error.response.status === 401) {
            const token = localStorage.getItem('jwt')
            if (token) {
                userLogout()
            }
        }
        console.log(error.response.data.message)
        return error.response.data.message;
    } else if (error.request) {
        return error.message;
    } else {
        return 'Something went wrong';
    }
};
