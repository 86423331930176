import React, { lazy } from 'react';

const CheckMail = lazy(() => import('app/views/auth/CheckMail'));
const ForgotPassword = lazy(() => import('app/views/auth/ForgotPassword'));
const Login = lazy(() => import('app/views/auth/Login'));
const Register = lazy(() => import('app/views/auth/Register'));

const AuthRoutes = [
  {
    path: "/auth/login",
    component: <Login />,
  },
  {
    path: "/auth/register",
    component: <Register />,
  },
  {
    path: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/auth/check-mail",
    component: <CheckMail />,
  },
]

export default AuthRoutes
