import { useState } from "react";
import AuthServices from "app/apis/auth/AuthServices";
import { SuccessToaster } from "app/components/toaster/Toaster";

function useProvideAuth() {

  // *For User
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  // *For Login
  const userLogin = (data) => {
    localStorage.setItem('user', JSON.stringify(data))
    setUser(data)
  };

  // *For Logout
  const userLogout = async () => {
    setUser(null)
    localStorage.clear()
    SuccessToaster('Logout Successfully')
    await AuthServices.logout()
  };

  return {
    user,
    userLogin,
    userLogout
  };
}

export default useProvideAuth;