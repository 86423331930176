import React from 'react';
import { Box, Container, Typography } from '@mui/material';

function Header() {

  return (
    <Box sx={{ py: 1.5 }}>
      <Container maxWidth="false">
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          LOGO
        </Typography>
      </Container>
    </Box>
  );
}

export default Header;