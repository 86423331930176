import { deleted, get, post, put } from 'app/apis';
import DocumentRoutes from './Document.Routes';

const DocumentServices = {
  addDocument: async (obj) => {
    const data = await post(DocumentRoutes.addDocument, obj);
    return data;
  },
  getDocuments: async (param) => {
    const data = await get(DocumentRoutes.getDocuments, param);
    return data;
  },
  getDocumentDetail: async (id) => {
    const data = await get(DocumentRoutes.getDocumentDetail + id);
    return data;
  },
  updateDocument: async (id, obj) => {
    const data = await put(DocumentRoutes.updateDocument + id, obj);
    return data;
  },
  deleteDocument: async (id) => {
    const data = await deleted(DocumentRoutes.deleteDocument + id);
    return data;
  },
  printDocument: async (id) => {
    const data = await get(DocumentRoutes.printDocument + id);
    return data;
  },
}

export default DocumentServices