import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";

import Colors from "app/assets/style/Colors";

export const PrimaryButton = (props) => {

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.disabled ? Colors.smokyBlack : Colors.white,
        textTransform: 'capitalize',
        boxShadow: 'none',
        minWidth: '120px',
        '&:hover': {
          background: props.disabled ? Colors.flashWhite : props.btnColor,
          color: props.disabled ? Colors.smokyBlack : Colors.white,
        }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {

  const { title } = props

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: Colors.white,
        color: Colors.textPrimary,
        textTransform: 'capitalize',
        ml: 1,
        minWidth: '120px',
        '&:hover': {
          background: Colors.white,
          color: Colors.textPrimary,
        }
      }}
    >
      {title}
    </LoadingButton>
  );
}

export const TertiaryButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: Colors.white,
        color: Colors.gunMetal,
        minWidth: '100px',
        textTransform: 'capitalize',
        fontWeight: 700,
        mr: 1,
        '&:hover': {
          bgcolor: Colors.white,
          color: Colors.gunMetal
        }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const ActionButton = (props) => {
  return (
    <IconButton {...props} sx={{ mx: 0.5, p: 0 }}>
      {props.children}
    </IconButton>
  );
}

export const PaginationButton = (props) => {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        py: 0.5,
        mx: 0.5,
        textTransform: 'capitalize',
        bgcolor: Colors.white,
        color: Colors.black,
        '&:hover': { bgcolor: Colors.white, color: Colors.black }
      }}
    >
      {props.children}
    </Button>
  );
}

export const BackButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="back"
      onClick={onClick}
      size="small"
      sx={{
        bgcolor: Colors.tertiary,
        color: Colors.white,
        '&:hover': {
          bgcolor: Colors.tertiary,
          color: Colors.white
        }
      }}
    >
      <ArrowBack />
    </IconButton>
  );
}