import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';

import Colors from 'app/assets/style/Colors';

function Footer() {

  return (
    <Box sx={{ py: 1.5, bgcolor: Colors.ghostWhite }}>
      <Container maxWidth="false">
        <Grid container spacing={1} alignItems="center" justifyContent={"space-between"} >
          <Grid item md={6}>
            <Typography variant="body2" color={Colors.textSecondary} >This site is protected by&nbsp;
              <Typography component={"span"} variant="body2" color={Colors.primary} sx={{ '&:hover': { textDecoration: 'underline' } }} >
                <Link href="/">Privacy Policy</Link>
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            <Typography component={"span"} variant="body2" color={Colors.textSecondary} sx={{ mx: 1.5, '&:hover': { textDecoration: 'underline' } }} >
              <Link href="/">Terms and Conditions</Link>
            </Typography>
            <Typography component={"span"} variant="body2" color={Colors.textSecondary} sx={{ mx: 1.5, '&:hover': { textDecoration: 'underline' } }} >
              <Link href="/">Privacy Policy</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;