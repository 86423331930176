import React, { useState } from 'react';
import { AppBar, Avatar, Box, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material';
import { Logout } from '@mui/icons-material';

import Colors from 'app/assets/style/Colors';
import useAuth from 'app/hooks/UseAuth';
import { UserAvatar } from 'app/assets/Images';
import { ErrorToaster, SuccessToaster } from 'app/components/toaster/Toaster';

function DropDown({ anchorEl, openDropdown, handleClose }) {

  const { userLogout } = useAuth();

  // *For Logout
  const logout = () => {
    try {
      userLogout()
    } catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '.MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <Logout fontSize="16px" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )
}

function Header() {

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  return (
    <AppBar elevation={0} position="static" sx={{ bgcolor: Colors.white, boxShadow: '0px 4px 4px #D7DCE8' }}>
      <Toolbar sx={{ px: { xl: 8, xs: 4 } }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Avatar sx={{ width: 30, height: 30, mr: 1 }}>
              <UserAvatar />
            </Avatar>
          </Box>
        </Box>
        <DropDown anchorEl={anchorEl} openDropdown={openDropdown} handleClose={() => setAnchorEl(null)} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;