import React, { Fragment, useState } from 'react';
import { Box, Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

import Navigations from 'app/Navigations';
import { invoizerPro } from 'app/assets/Images';
import Colors from 'app/assets/style/Colors';

const drawerWidth = 250;

function Sidebar() {

  const { pathname } = useLocation();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Sub Child Active
  const handleSubChildActive = (item) => {
    return (item.childPath?.indexOf(pathname) !== -1 && item.childPath?.indexOf(pathname))
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          bgcolor: Colors.white,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: 6,
            bgcolor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 1,
            bgcolor: Colors.primary,
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ textAlign: 'center', p: 1, mb: 1 }}>
        {/* <img src={Images.logo} alt="Takseem" width="70%" /> */}
        {/* <invoizerPro /> */}
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          LOGO
        </Typography>
        <Divider sx={{ width: '80%', mx: 'auto' }} />
      </Box>
      <List sx={{ px: 2 }}>
        {Navigations.map((item, index) => (
          <Fragment key={index}>
            <ListItemButton key={index}
              component={item.path ? Link : 'div'}
              to={item.path ?? ''}
              onClick={() => item.childPath && handleCollapse(item.label)}
              sx={{
                borderRadius: 1,
                mt: 1.5,
                bgcolor: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.primary + '14' : "",
                '&:hover': {
                  bgcolor: Colors.primary + '14',
                  color: Colors.primary,
                  ".MuiTypography-root": {
                    color: Colors.primary
                  },
                  ".MuiListItemIcon-root": {
                    color: Colors.primary
                  }
                }
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.primary : Colors.textSecondary,
                  minWidth: 40, fontSize: "20px"
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2"
                    sx={{
                      fontSize: 13,
                      fontWeight: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? "bold" : "normal",
                      color: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.primary : Colors.textSecondary
                    }}>
                    {item.label}
                  </Typography>
                }
              />
              {item?.children &&
                (expand.indexOf(item.label) !== -1 ?
                  <ExpandLess sx={{ color: Colors.textSecondary }} /> : <ExpandMore sx={{ color: Colors.textSecondary }} />)
              }
            </ListItemButton>
            {item?.children &&
              <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ? true : false} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child, i) => (
                    <ListItemButton key={i}
                      component={child.path ? Link : 'div'}
                      to={child.path ?? ''}
                      onClick={() => child.path && handleCollapse(child.label)}
                      sx={{
                        borderRadius: 1,
                        mt: 1,
                        '&:hover': {
                          bgcolor: Colors.white,
                          color: Colors.primary,
                          ".MuiTypography-root": {
                            color: Colors.primary
                          },
                          ".MuiListItemIcon-root": {
                            color: Colors.primary
                          }
                        }
                      }}
                    >
                      <ListItemIcon sx={{ color: child.path === pathname ? Colors.primary : Colors.textSecondary, minWidth: 40, '.MuiSvgIcon-root': { fontSize: "14px" } }}>
                        {child.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography type="body2"
                            sx={{
                              fontSize: 13,
                              fontWeight: child.path === pathname ? "bold" : "normal",
                              color: child.path === pathname ? Colors.primary : Colors.textSecondary
                            }}>
                            {child.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            }
          </Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;