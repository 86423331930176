import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';

import Colors from 'app/assets/style/Colors';
import Images from 'app/assets/Images';
import Header from './Header';
import Footer from './Footer';
import { CircleLoading, Preloader } from 'app/components/loaders/Loaders';

function AuthLayout() {
  return (
    <Box sx={{ backgroundImage: `url(${Images.authBanner})`, width: 1, backgroundSize: 'cover', }}>

      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <Box component="main" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '470px', height: 'calc(100vh - 130px)' }}>
        <Box sx={{ width: '400px', bgcolor: Colors.white + 'e6', p: 5, borderRadius: 2, boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 4px' }}>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

    </Box>
  )
}

export default AuthLayout