import { DashboardOutlined, DescriptionOutlined, GroupsOutlined, Inventory2Outlined } from "@mui/icons-material"

const Navigations = [
  {
    label: 'Dashboard',
    icon: <DashboardOutlined />,
    path: '/',
  },
  {
    label: 'Invoices',
    icon: <DescriptionOutlined />,
    path: '/invoices',
  },
  {
    label: 'Clients',
    icon: <GroupsOutlined />,
    path: '/clients',
  },
  // {
  //   label: 'Products',
  //   icon: <Inventory2Outlined />,
  //   path: '/products',
  //   // childPath: ['/invoices', '/clients'],  // must have to enter child path here if item has children
  //   // children: [
  //   //   {
  //   //     label: 'Details',
  //   //     icon: <FiberManualRecord />,
  //   //     path: '/invoices'
  //   //   },
  //   //   {
  //   //     label: 'Employees',
  //   //     icon: <FiberManualRecord />,
  //   //     path: '/clients'
  //   //   },
  //   // ]
  // },
]

export default Navigations