import EditInvoice from "app/views/invoices/EditInvoice";
import React, { lazy } from "react";

const Clients = lazy(() => import('app/views/clients'));
const NewClient = lazy(() => import('app/views/clients/NewClient'));
const EditClient = lazy(() => import('app/views/clients/EditClient'));
const Dashboard = lazy(() => import('app/views/dashboard'));
const Invoices = lazy(() => import('app/views/invoices'));
const NewInvoice = lazy(() => import('app/views/invoices/NewInvoice'));
const Products = lazy(() => import('app/views/products'));

const AdminRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/invoices",
    component: <Invoices />,
  },
  {
    path: "/invoices/new-invoice",
    component: <NewInvoice />,
  },
  {
    path: "/invoices/edit-invoice/:id",
    component: <EditInvoice />,
  },
  {
    path: "/clients",
    component: <Clients />,
  },
  {
    path: "/clients/new-client",
    component: <NewClient />,
  },
  {
    path: "/clients/edit-client/:id",
    component: <EditClient />,
  },
  {
    path: "/products",
    component: <Products />,
  },
]

export default AdminRoutes
