const Colors = {
    primary: '#FF8B00',
    primaryGradient: 'linear-gradient(180deg, #FF8B00 0%, #DA7B0A 100%)',
    secondary: '#0185BA',
    secondaryGradient: 'linear-gradient(180deg, #0184B9 0%, #046990 100%)',
    tertiary: '#27DEBF',
    textPrimary: '#B1B4B9',
    textSecondary: '#8c8c8c',
    gunMetal: '#263238',
    black: '#222222',
    white: '#ffffff',
    smokeyGrey: '#747474',
    desertStorm: '#f7f7f7',
    ghostWhite: '#F5F8FC ',
    flashWhite: '#EFF0F6',
    danger: '#FF2121',
    success: '#79AA5A',
};

export default Colors;